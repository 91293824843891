import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="box-model" mdxType="Subpage">
      <h3>{`The Box Model`}</h3>
      <Callout number="8.1" term="The box model..." color="alternate" mdxType="Callout">
        <p>{`...is a conceptual framework for understanding the series of CSS properties that affect elements' size, border, background, and spacing.`}</p>
      </Callout>
      <p>{`Working with CSS basic properties and typography so far has allowed us to learn the ropes of CSS and have a lot more control of how our content looks. But we haven’t had much control over layout adjustments yet. This topic will be covered in the next few modules as these skills involve several different layers.
In this module we will lay the foundation by looking at the box model. In the next module we’ll talk about how to achieve more complex layouts using the float and clear properties. In the following two modules we’ll look at even more possibilities with relative and absolute positioning.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Ch. 13 from Duckett`}</p>
        <small>for an explanation of many of the box model properties.</small>
      </Callout>
      <p>{`Once again, the assigned reading from the Duckett textbook provided an excellent summary of the tools and concepts behind the box model. This set of CSS properties allows us to control the dimensions (width and height), white space (margin and padding), borders (border and related properties), and background colors and imagery (background and its related properties) to add even more visual finesse to our HTML elements (these will be covered in the upcoming Duckett reading from chapter 14).`}</p>
      <p>{`Here is a table summarizing the properties, their uses, potential values, and common examples:`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Property`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`What it does`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Value options`}</th>
            <th parentName="tr" {...{
              "align": "left"
            }}>{`Examples`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`width`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Controls content box width`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`auto`}</code>{` `}{`|`}{` amount in `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`px`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{`, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`%`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`width: 500px;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`height`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Controls content box height`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`auto`}</code>{` `}{`|`}{` amount in `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`px`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{`, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`%`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`height: auto;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`margin`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes outer whitespace (outside border)	auto`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`amount in `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`px`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{`, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`%`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`margin: 10px 20px;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`padding`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes inner whitespace (inside border)`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`amount in `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`px`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`em`}</code>{`, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`%`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`padding: 30px 10px 50px 40px;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border-width`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Controls the thickness of the border`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`amount in `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`px`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`thin`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`medium`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`thick`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border-width: 5px;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border-style`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Controls the style or type of the border`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`solid`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`dotted`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`dashed`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`double`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`groove`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`ridge`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`inset`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`outset`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`hidden`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border-style: solid;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border-color`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Controls the color of the border`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Color keyword, hex code, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`rgb()`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`rgba()`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`hsl()`}</code>{`, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`hsla()`}</code>{` color code`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border-color: #ef4520;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Adds or removes border around content dimensions and padding`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Shorthand that allows you to write all the border types in one property`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`border: 1px solid #000;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-color`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Controls the color of a box’s background area, the area inside the border behind padding whitespace and content`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Color keyword, hex code, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`rgb()`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`rgba()`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`hsl()`}</code>{`, or `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`hsla()`}</code>{` color code`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-color: #ef4520;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-image`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Allows us to specify an image to appear and possibly tile in the box’s background area`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`url('path/to/image.jpg')`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-image: url("images/logo.png");`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-repeat`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Allows us to set if and how a background image will tile`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`repeat`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`repeat-x`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`repeat-y`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`no-repeat`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-repeat: no-repeat;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-position`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Provides the origin location for a background image`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`xpx ypx`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`x% y%`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`left top`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`left center`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`left bottom`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`right top`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`right center`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`right bottom`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`center top`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`center center`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`center bottom`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-position: center top;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-attachment`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Sets whether an image stays fixed as content scrolls or whether it moves with the content`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`fixed`}</code>{` `}{`|`}{` `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`scroll`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background-attachment: fixed;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Shortcut property to set all background settings at once`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Any background property values separated by a space.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`background: #fff url("image.png") repeat-y fixed left;`}</code></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`box-sizing`}</code></td>
            <td parentName="tr" {...{
              "align": "left"
            }}>{`Determines which box properties are included when width and height are set.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`content-box`}</code>{` is the default; `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`border-box`}</code>{` ensures that padding and borders are both included in dimension settings.`}</td>
            <td parentName="tr" {...{
              "align": "left"
            }}><code parentName="td" {...{
                "className": "language-text"
              }}>{`box-sizing: border-box`}</code></td>
          </tr>
        </tbody>
      </table>
    </Subpage>
    <Subpage slug="block-vs-inline-revisited" mdxType="Subpage">
      <h3>{`Revisiting Block vs. Inline`}</h3>
      <Callout number="8.2" term="We can convert between Block and Inline formatting..." color="alternate" mdxType="Callout">
        <p>{`...using the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`display`}</code>{` property. This become helpful particularly when trying to apply box model settings to inline elements.`}</p>
      </Callout>
      <p>{`Earlier in the course we discussed how all HTML elements are either inline or block-level elements. It is important to note that box model styles behave differently for these. The simple statement is that the box model does not work on inline elements. The long statement begins with the fact that browsers allow different box-model settings on inline elements and interpret them differently. So, favoring the simple statement in this introductory course, avoid using box-model settings on inline elements.`}</p>
      <p>{`However, a CSS property allows you to convert elements to block-level elements (or to inline if desired): the display property. This accepts the several values including block, inline and none.`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`display: block`}</code>{` converts an element to a block-level element. This is very helpful, particularly with elements such as hyperlinks that are part of a navigation list. This way we can add `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`padding`}</code>{` around the link and set a background color, then use the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`: hover`}</code>{` pseudo-class to provide an altered appearance when the user hovers over the link.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`display: inline`}</code>{` converts an element to an inline element. This is occasionally helpful such as when you have an `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1`}</code>{`> and a `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<p>`}</code>{` inside a `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{`. If the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` is the brand and the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<p>`}</code>{` is a tagline and you want them to appear on the same line, you could set them both to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`display: inline`}</code>{`. However, many times it is more effective to use the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`float`}</code>{` property or flex box to get elements to appear side-by-side—and that is covered in the next module.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`display: none`}</code>{` makes an element altogether invisible. This can be helpful when adding interactive elements later in your studies or for toggling the visibility of certain elements in responsive web design (which is covered later in this course).`}</li>
      </ul>
    </Subpage>
    <Subpage slug="simplifying-box-dimensions" mdxType="Subpage">
      <h3>{`Simplifying Box Dimensions`}</h3>
      <Callout number="8.3" term="The 'box-sizing: border-box' setting..." color="alternate" mdxType="Callout">
        <p>{`...allows us to simplify controlling and calculating box dimensions because it ensures `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`padding`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`border`}</code>{` are included inside an element's `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`width`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`height`}</code>{` setting instead of outside these.`}</p>
      </Callout>
      <p>{`The box model is very helpful for understanding the properties that contribute to any element’s size, spacing, stroke and fill. One trick to note is the relationship between spacing, border and size properties in relation to controlling an elements overall dimensions.`}</p>
      <p>{`First of all, note that by default, margin, padding, border and width/height all add up to create the box’s total dimensions. For example, what are the total width and height of this element:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.box`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 500px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`height`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 150px
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 10px 5px 10px 5px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 10px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`border`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 1px solid black`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`By default, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`width`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`height`}</code>{` control the dimensions of the element’s content box (the actual area the text or other content fills) and then `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`padding`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`border`}</code>{`, and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin`}</code>{` all wrap around that size.`}</p>
      <p>{`So if we wanted to figure out the total width we'd need to run the following calculation:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-text line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{` 500px     (width)                     --> 500px
   5px × 2 (margin on left and right)  -->  10px
  10px × 2 (padding on left and right) -->  20px
+  1px × 2 (border on left and right)  -->   2px
-----------
 532px`}</code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`The height would be the following:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-text line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{` 150px     (height)                    --> 150px
  10px × 2 (margin on top and bottom)  -->  20px
  10px × 2 (padding on top and bottom) -->  20px
+  1px × 2 (border on top and bottom)  -->   2px
-----------
 192px`}</code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`If this seems overly complex, consider using the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`box-sizing`}</code>{` property to simplify this a little.`}</p>
      <p>{`But if you set this property...`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token property"
            }}>{`box-sizing`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` border-box`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`...then the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`width`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`height`}</code>{` settings control the total space from border to border. So in this model, only `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`margin`}</code>{` adds to this overall size.`}</p>
      <p>{`So to modify the example above:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`.box`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`box-sizing`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` border-box`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 500px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`height`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 150px
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 10px 5px 10px 5px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`padding`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 10px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`border`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 1px solid black`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`Now the total width is simply:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-text line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{`  500px     (width)                    --> 500px
+   5px x 2 (margin on left and right) -->  10px
------------
  510px`}</code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`border`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`padding`}</code>{` are simply included in the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`500px`}</code>{` setting for width, so technically, the content box is now smaller (`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`478px`}</code>{` to be exact). This may be tricky at first, but I believe you’ll find it much simpler to get used to controlling box dimensions if you also use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`box-sizing: border-box`}</code>{`.`}</p>
    </Subpage>
    <Subpage slug="centered-single-column" mdxType="Subpage">
      <h3>{`Creating and Centering a Single-column Layout`}</h3>
      <Callout number="8.4" term="Center elements from a layout perspective..." color="alternate" mdxType="Callout">
        <p>{`...using `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`auto`}</code>{` margin on left and right and setting a width on the element.`}</p>
      </Callout>
      <p>{`The most basic of websites today will typically have a set of page content filling a certain width and centered in the browser window, as pictured below.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/market-centered.jpg",
          "alt": null
        }}></img></p>
      <p>{`This single-column layout is created by setting width on an element such as `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<body>`}</code>{` and then using the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`auto`}</code>{` setting for left and right margin:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`body`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 990px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0 auto`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`This centering effect can also be used at other levels to create centered layout elements. In a situation like the one below, a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<header>`}</code>{` has a background color but has no width set, so it is filling the browser window’s width. Then an `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` and a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<p>`}</code>{` inside it each have a width set along with auto margin on the left and right.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/market-centered-2.jpg",
          "alt": null
        }}></img></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "css"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-css line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-css"
          }}><span parentName="code" {...{
              "className": "token selector"
            }}>{`header`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`background-color`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` red`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`header h1`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 930px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0 auto`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token selector"
            }}>{`header p`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`width`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 930px`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token property"
            }}>{`margin`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` 0 auto`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    </Subpage>
    <Subpage slug="backgrounds-and-other-effects" mdxType="Subpage">
      <h3>{`Styling with Backgrounds and Other Effects`}</h3>
      <Callout number="8.5" term="The 'background-...' settings..." color="alternate" mdxType="Callout">
        <p>{`...allow a variety of layering effects including laying text over a texture or image and creating tiling patterns.`}</p>
      </Callout>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Ch. 16 from Duckett`}</p>
        <small>for details about how to work with images in CSS, including setting dimensions of images in your markup as well as how to add images as a background behind other HTML elements. You also get a preview of the float property, which we’ll discuss more in the next module. Note pp.413–416 in particular for a summary of the various settings that control backgrounds of boxes.</small>
      </Callout>
      <p>{`When we design entire webpage layouts we often include visual effects that we need to export and use in our CSS as backgrounds on elements. Consider the backgrounds in the elements on this page:`}</p>
      <p><img parentName="p" {...{
          "src": "/images/andrew-peterson.jpg",
          "alt": null
        }}></img></p>
      <p>{`Here you can see a texture fills the browser window beyond the boundaries of the “page,” while a different texture sits directly behind each of the elements on the page. In this case the back-most texture is likely added onto the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<body>`}</code>{` tag (which fills the entire browser window), while each content module also has its own background image or color. Complex layering is very easy to accomplish thanks to the box model and the way elements contain other elements in HTML.`}</p>
      <p>{`But in order to create these effects we must export images from our design comps successfully. Here are some things to consider:`}</p>
      <ul>
        <li parentName="ul">{`Backgrounds can tile in CSS so only export a small piece of a texture when that texture can repeat either in all directions, top to bottom, or left to right.`}</li>
        <li parentName="ul">{`Watch for images that layer on top of other textures. Rather than exporting the image and the texture, separate the two and export the top image on a transparent background. Then you can achieve the same layered effect using HTML and CSS.`}</li>
        <li parentName="ul">{`JPEG is a good default format to use for exporting slices unless they have transparency in which case PNG is likely the best so as to achieve smooth transparency and colors.`}</li>
      </ul>
      <Callout number="8.6" term="Other effects..." color="alternate" mdxType="Callout">
        <p>{`...such as `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`border-radius`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`box-shadow`}</code>{` allow us to further modify our box elements`}</p>
      </Callout>
      <p>{`Note these additional settings that help with decorating boxes:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`border-radius`}</code>{` — allows us to set rounded borders on elements. See this `}<a parentName="li" {...{
            "href": "https://css-tricks.com/almanac/properties/b/border-radius/"
          }}>{`CSS Tricks article`}</a>{` for more on how to use this and its variations.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`box-shadow`}</code>{` — allows us to provide drop shadows of sorts on our boxes. `}<a parentName="li" {...{
            "href": "https://css-tricks.com/snippets/css/css-box-shadow/"
          }}>{`CSS Tricks`}</a>{` has a great demo of how to use this property.`}</li>
      </ul>
    </Subpage>
    <Subpage slug="more-css-color" mdxType="Subpage">
      <h3>{`More on Color in CSS`}</h3>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Ch. 11 from Duckett`}</p>
        <small>for more details about working with color in CSS.</small>
      </Callout>
      <p>{`Note pp.251–254 in particular where RGB, Hex, and RGBA are discussed. On p.254 Duckett brings up `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`opacity`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`rgba`}</code>{`. Note the following differences:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`opacity`}</code>{` is an actual `}<em parentName="li">{`CSS property`}</em>{` that governs the transparency of the entire element selected. Therefore, use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`opacity`}</code>{` when you want to make an entire element and its content semi-transparent.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`rgba`}</code>{` is a potential `}<em parentName="li">{`color value`}</em>{` you can use for the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`color`}</code>{` or `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`background-color`}</code>{` properties. It is therefore useful when you want to set just a semi-transparent color such as on a background.`}</li>
      </ul>
    </Subpage>
    <Subpage slug="marketing-and-seo" mdxType="Subpage">
      <h3>{`Marketing and Search Engine Optimization`}</h3>
      <p>{`While not a major emphasis in this course, we do want you to be exposed to what happens after websites launch. In this sphere, focus can be placed on evaluating how well the site actually meets the intended goals. The last few chapters of Miller provide an overview of related topics.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Chapters 7–9 from Miller.`}</p>
      </Callout>
    </Subpage>
    <Subpage slug="study-tools" mdxType="Subpage">
      <h3>{`Study Tools`}</h3>
      <p>{`Prepare answers to the following questions as you study for the exam covering this lesson:`}</p>
      <ul>
        <li parentName="ul">{`What is search engine optimization and what are some techniques commonly used to accomplish this?`}</li>
        <li parentName="ul">{`What are conversions and what techniques are often used to ensure them?`}</li>
      </ul>
      <p>{`Memorize each of the following CSS properties or values:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`width`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`height`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`margin`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`padding`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`border-width`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`border-style`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`border-color`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`border`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`background-image`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`background-color`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`background-repeat`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`background-position`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`background-attachment`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`background`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`box-sizing`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`display`}</code></li>
      </ul>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      